@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aladin&display=swap');

*, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

// For rounded look
.btn, .form-control, .project, .project-btn, .contact, .contact-data {
  border-radius: 30px;
  transition: 0.3s;
}

.btn:hover, .project-btn:hover {
  transform: scale(1.05);
}

.form-check .form-check-input {
  border-radius: 50%;
}

#toggle-theme > ion-icon {
  font-size: 30px;
  margin-top: 6px
}

.main-button {
    color: #fff;
    background-color: #1f1f1f38;
    border: 3px double white;
    backdrop-filter: blur(10px);
}


body {
  font-family: 'Josefin Sans', serif;
  background: #fff;
  line-height: 1.7;
  font-size: 18px;
}

.font-aladin {
  font-family: 'Aladin', serif;
  font-size: 1.3rem;
}

.navbar {
    padding-top: 0;
    padding-bottom: 0;
    height: 70px;
    

    #logo {
        height: 60px;
    }

    .logo-text {
      font-family: 'Aladin', cursive;
      font-size: 1.5rem;
  }

}

.main-header {
    position: relative;
    background: url("./../img/main/background.jpg") no-repeat center center/cover;
    height: 95vh;
    width: 100%;
    text-align: center;
    padding: 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    .main-content {
      max-width: 800px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .main-content-text {
      background: #28282878;
      color: #fff;
      border-radius: 30px;
      backdrop-filter: blur(10px);
    }

    .btn-outline-primary {
        color: #ffffff;
        border-color: #ffffff;
    }

    .btn-outline-primary:hover {
        color: #2f2f2f;
        background-color: #fff;
        border-color: #fff;
    }
    .btn {
        font-size: 1rem;
        display: flex;
        align-items: center;
    }

    ion-icon {
        margin-left: 7px;
    }
}

/* Overlay */
.header::before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, var(--header-opacity));
  position: absolute;
  top: 0;
  left: 0;
}



/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #767676;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #b8b8b8;
}

.skill img {
  margin: 20px !important;
}

// Projects Page
.project, .contact-data {
  border: 1px solid #eee;
}

.project-btn {
    text-decoration: none;
    font-weight: 200;
    border: 1px solid #918d8d;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: none;
}

// Not found Page
.container-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

ion-icon {
  font-size: 16px;
}


// Profile image 
.profile-image-container, .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.in-flex-center {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}

.in-flex-center > ion-icon {
    margin-left: 0.3rem;
    margin-right: 0.2rem;
}

.profile-image {
    max-width: 400px;
    width: 100%;
    height: auto;
    border-radius: 50%;
}

.text-justify {
  text-align: justify;
}

.project-container ol li {
  margin-bottom: 1.5rem;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.travel {
    .categories {
      display: flex;
  }

  .category {
      background: #1a1a1a;
      color: #fff;
      margin-right: 20px;
      padding: 10px;
      border-radius: 100px;
  }

  .category {
      background: #1a1a1a;
      color: #fff;
      margin-right: 20px;
      padding: 10px 20px;
      border-radius: 100px;
  }

    .category-active {
      background: #fff;
      color: #1a1a1a;
  }
}

.about {
  margin-top: 15vh;
  display: flex;
  align-items: center;
  justify-content: center;
}