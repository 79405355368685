.mobile-menu {
  display: none;
}

@media (max-width: 992px) {
  .desktop-menu {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .mobile-menu {
    display: block;
    position: fixed;
    top: 0;
    right: 100px;
    z-index: 1;
  }

  .mobile-menu .toggler {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
    width: 60px;
    height: 60px;
    opacity: 0;
  }

  .mobile-menu .hamburger {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 70px;
    height: 70px;
    padding: 1rem;
    background: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Hamburger Line */
  .mobile-menu .hamburger > div {
    position: relative;
    flex: none;
    width: 100%;
    height: 2px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease;
  }

  /* Hamburger Lines - Top & Bottom */
  .mobile-menu .hamburger > div::before,
  .mobile-menu .hamburger > div::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: -10px;
    width: 100%;
    height: 2px;
    background: inherit;
  }

  /* Moves Line Down */
  .mobile-menu .hamburger > div::after {
    top: 10px;
  }

  /* Toggler Animation */
  .mobile-menu .toggler:checked + .hamburger > div {
    transform: rotate(135deg);
  }

  /* Turns Lines Into X */
  .mobile-menu .toggler:checked + .hamburger > div:before,
  .mobile-menu .toggler:checked + .hamburger > div:after {
    top: 0;
    transform: rotate(90deg);
  }

  /* Rotate On Hover When Checked */
  .mobile-menu .toggler:checked:hover + .hamburger > div {
    transform: rotate(225deg);
  }

  /* Show Menu */
  .mobile-menu .toggler:checked ~ .menu {
    visibility: visible;
  }

  .mobile-menu .toggler:checked ~ .menu > div {
    transform: scale(1);
    transition-duration: 0.75s;
  }

  .mobile-menu .toggler:checked ~ .menu > div > div {
    opacity: 1;
    transition: opacity 0.4s ease 0.4s;
  }

  .mobile-menu .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-menu .menu > div {
    background: rgba(0, 0, 0, 1);
    border-radius: 50%;
    width: 200vw;
    height: 200vw;
    display: flex;
    flex: none;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    transition: all 0.4s ease;
  }

  .mobile-menu .menu > div > div {
    text-align: center;
    max-width: 90vw;
    max-height: 100vh;
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  .mobile-menu .menu > div > div > ul {
    flex-direction: column;
  }

  .mobile-menu .menu > div > div > ul > li {
    list-style: none;
    color: #fff;
    font-size: 1.5rem;
    padding: 1rem;
  }

  .mobile-menu .menu > div > div > ul > li > a {
    color: inherit;
    text-decoration: none;
    transition: color 0.4s ease;
    margin-left: 0;
  }
}
